import { Job } from "../../types";
import { cranes } from "../equipment/equipmentList";

import childrensLift from "../../images/childrens.jpg";

const jobs: Job[] = [
  {
    title: "Childrens Hospital Air Handler Replacement",
    description: `We replaced a 30,000 lb air handler from the top of Childrens
    Hospital. This lift involved our LTM 1450 crane. The area was a
    tight fit but we were able to maneauver the air handler without any
    issues`,
    date: "November 2020",
    location: "Boston MA",
    equipmentUsed: [cranes[1]],
    specs: [
      { title: "Pick Height", value: "300ft" },
      { title: "Pick Radius", value: "400ft" },
      { title: "Load Weight", value: "30,000 lbs" },
      { title: "Clearance", value: "10ft" },
    ],
    photoUrls: [childrensLift],
    summaryPhoto: childrensLift,
  },
];

export { jobs };
